export class ApiError extends Error {
  constructor(message, status, data = null) {
    super(message);
    this.name = "ApiError";
    this.status = status;
    this.data = data;
  }
}

export class NetworkError extends ApiError {
  constructor(message = "Network error - please check your connection") {
    super(message, 0);
    this.name = "NetworkError";
  }
}

export class AuthenticationError extends ApiError {
  constructor(message = "Authentication failed") {
    super(message, 401);
    this.name = "AuthenticationError";
  }
}

export class AuthorizationError extends ApiError {
  constructor(message = "You do not have permission to perform this action") {
    super(message, 403);
    this.name = "AuthorizationError";
  }
}

export class NotFoundError extends ApiError {
  constructor(message = "The requested resource was not found") {
    super(message, 404);
    this.name = "NotFoundError";
  }
}

export class ValidationError extends ApiError {
  constructor(
    message = "Validation failed - please check your input",
    data = null
  ) {
    super(message, 422, data);
    this.name = "ValidationError";
  }
}

export class ServerError extends ApiError {
  constructor(message = "Something went wrong - please try again later") {
    super(message, 500);
    this.name = "ServerError";
  }
}
