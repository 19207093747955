import { BaseService } from "../api/BaseService";

class MemberService extends BaseService {
  constructor() {
    super("/member");
  }

  _getAdminConfig(customConfig = {}) {
    return {
      ...customConfig,
      requiresAdmin: true,
    };
  }

  async getAllMembers() {
    try {
      const response = await this.get("/list", this._getAdminConfig());

      if (Array.isArray(response)) {
        return response;
      }

      if (response?.data && Array.isArray(response.data)) {
        return response.data;
      }

      return [];
    } catch (error) {
      console.error("Error in getAllMembers:", error);
      console.error("Error details:", {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });

      if (error.response?.status === 404) {
        return [];
      }

      console.error("Error fetching members:", error);
      throw error;
    }
  }

  async getMember(id) {
    try {
      const response = await this.get(`/get/${id}`, this._getAdminConfig());
      return response.data;
    } catch (error) {
      console.error(`Error fetching member ${id}:`, error);
      throw error;
    }
  }

  async createMember(memberData) {
    try {
      const formDataToSend = new FormData();

      if (memberData instanceof FormData) {
        return await this.post("/create", memberData, this._getAdminConfig());
      }

      if (memberData.img instanceof File) {
        formDataToSend.append("image", memberData.img);
      }

      const fieldsToSend = ["name", "title", "status"];
      fieldsToSend.forEach((field) => {
        if (memberData[field]) {
          formDataToSend.append(field, memberData[field]);
        }
      });

      if (memberData.social) {
        const socialData = Array.isArray(memberData.social)
          ? JSON.stringify(memberData.social)
          : memberData.social;
        formDataToSend.append("social", socialData);
      }

      const response = await this.post(
        "/create",
        formDataToSend,
        this._getAdminConfig()
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 400) {
        console.error("Bad request error:", error.response.data);
        throw new Error(
          error.response.data.message || "Failed to create member"
        );
      }
      console.error("Error creating member:", error);
      throw error;
    }
  }

  async updateMember(id, memberData) {
    try {
      const formDataToSend = new FormData();

      if (memberData instanceof FormData) {
        const response = await this.put(
          `/update/${id}`,
          memberData,
          this._getAdminConfig()
        );
        return response.data;
      }

      if (memberData.img instanceof File) {
        formDataToSend.append("image", memberData.img);
      }

      const fieldsToSend = ["name", "title", "status"];
      fieldsToSend.forEach((field) => {
        if (memberData[field]) {
          formDataToSend.append(field, memberData[field]);
        }
      });

      if (memberData.social) {
        const socialData = Array.isArray(memberData.social)
          ? JSON.stringify(memberData.social)
          : memberData.social;
        formDataToSend.append("social", socialData);
      }

      const response = await this.put(
        `/update/${id}`,
        formDataToSend,
        this._getAdminConfig()
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 400) {
        console.error("Bad request error:", error.response.data);
        throw new Error(
          error.response.data.message || "Failed to update member"
        );
      }
      console.error(`Error updating member ${id}:`, error);
      throw error;
    }
  }

  async deleteMember(id) {
    try {
      const response = await this.delete(
        `/delete/${id}`,
        this._getAdminConfig()
      );
      return response.data;
    } catch (error) {
      console.error(`Error deleting member ${id}:`, error);
      throw error;
    }
  }
}

export const memberService = new MemberService();
