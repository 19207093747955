import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import logoWhite from "../assets/Logo-White.webp";
import blog from "../assets/blog.webp";
import event from "../assets/event.webp";
import proje from "../assets/project.webp";
import { BsLink, BsLinkedin, BsGithub } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import ScrollToBottomButton from "../components/ScrollToBottomButton";
import { motion } from "framer-motion";
import { BsArrowRight } from "react-icons/bs";
import SEOHelmet from "../components/SEOHelmet";
import { memberService } from "../services/modules";
import LoadingSpinner from "../components/LoadingSpinner";

const SOCIAL_ICON_MAP = {
  "fab fa-linkedin": BsLinkedin,
  "fab fa-github": BsGithub,
  "fas fa-link": BsLink,
};

const Home = () => {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadMembers();
  }, []);

  const loadMembers = async () => {
    try {
      setLoading(true);
      const data = await memberService.getAllMembers();

      if (Array.isArray(data)) {
        const activeMembers = data
          .filter((member) => member.status !== "inactive")
          .map((member) => ({
            ...member,
            social: member.social.map((link) => ({
              ...link,
              icon: SOCIAL_ICON_MAP[link.icon] || BsLink,
            })),
          }));
        setMembers(activeMembers);
      }
    } catch (error) {
      console.error("Error loading members:", error);
    } finally {
      setLoading(false);
    }
  };

  const sections = [
    {
      id: "blog",
      img: blog,
      title: t("pages.home.section2.blogs.title"),
      content: t("pages.home.section2.blogs.content"),
      buttonText: t("pages.home.section2.blogs.button"),
      link: "/blog",
      gradientFrom: "#227064",
      gradientTo: "#705F22",
    },
    {
      id: "event",
      img: event,
      title: t("pages.home.section2.events.title"),
      content: t("pages.home.section2.events.content"),
      buttonText: t("pages.home.section2.events.button"),
      link: "/events",
      gradientFrom: "#59573C",
      gradientTo: "#AE8B93",
    },
    {
      id: "projects",
      img: proje,
      title: t("pages.home.section2.projects.title"),
      content: t("pages.home.section2.projects.content"),
      buttonText: t("pages.home.section2.projects.button"),
      link: "/projects",
      gradientFrom: "#2b6bc5",
      gradientTo: "#3ae4c8",
    },
  ];

  return (
    <main>
      <article>
        <section>
          <SEOHelmet
            title={t("helmet.title.homePageTitle")}
            description={t("seo.home.description")}
            keywords={t("seo.home.keywords")}
          />
          <div>
            <Helmet>
              <title>{t("helmet.title.homePageTitle")}</title>
              <link rel="preload" href={logoWhite} as="image" />
            </Helmet>
            <ScrollToBottomButton />

            <div className="min-h-screen bg-gradient-to-br from-first to-second relative overflow-hidden">
              <div className="absolute inset-0">
                <div className="absolute top-20 left-10 w-72 h-72 bg-white/5 rounded-full blur-3xl"></div>
                <div className="absolute bottom-20 right-10 w-96 h-96 bg-white/5 rounded-full blur-3xl"></div>
              </div>

              <div className="relative container mx-auto px-4 min-h-screen flex items-center mb-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                  <div className="text-white space-y-8">
                    <h1 className="text-5xl md:text-7xl font-acquire leading-tight">
                      {t("pages.home.section1.name")}
                    </h1>

                    <div className="space-y-4 text-lg md:text-xl text-gray-200">
                      <p className="leading-relaxed">
                        {t("pages.home.section1.p1")}
                      </p>
                      <p className="leading-relaxed">
                        {t("pages.home.section1.p2")}
                      </p>
                    </div>
                  </div>

                  <div className="relative flex justify-center items-center">
                    <div className="relative w-full max-w-md aspect-square">
                      <div className="absolute inset-0 rounded-full border-4 border-white/20 animate-spin-slow"></div>
                      <div className="absolute inset-4 rounded-full border-4 border-white/15 animate-spin-slow-reverse"></div>

                      <div className="absolute inset-8">
                        {!imageLoaded && (
                          <div className="absolute inset-0 bg-white/10 animate-pulse rounded-full" />
                        )}
                        <img
                          alt="TAU Blockchain Logo"
                          src={logoWhite}
                          className={`w-full h-full object-contain transition-opacity duration-700 ${
                            imageLoaded ? "opacity-100" : "opacity-0"
                          }`}
                          loading="lazy"
                          decoding="async"
                          onLoad={() => setImageLoaded(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute bottom-8 hidden md:block left-1/2 transform -translate-x-1/2">
                <div className="w-6 h-10 border-2 border-white/50 rounded-full flex justify-center">
                  <div className="w-1 h-3 bg-white/50 rounded-full mt-2 animate-bounce"></div>
                </div>
              </div>
            </div>

            <div className="relative min-h-screen bg-gradient-to-bl from-second to-[#355e00] py-20">
              <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {sections.map((section, index) => (
                    <motion.div
                      key={section.id}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true, margin: "-100px" }}
                      transition={{ duration: 0.8, delay: index * 0.1 }}
                      className="group relative overflow-hidden rounded-2xl bg-white/5 backdrop-blur-sm p-6"
                    >
                      <div className="relative z-10">
                        <div className="h-48 mb-6 overflow-hidden rounded-xl">
                          <img
                            src={section.img}
                            alt={section.title}
                            className="w-full h-full object-cover"
                            loading="lazy"
                          />
                        </div>

                        <h3 className="text-2xl font-bold text-white mb-4">
                          {section.title}
                        </h3>

                        <p className="text-gray-200 mb-6 line-clamp-3">
                          {section.content}
                        </p>

                        <NavLink
                          to={section.link}
                          className="inline-flex items-center text-white gap-2"
                        >
                          <span>{section.buttonText}</span>
                          <BsArrowRight />
                        </NavLink>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
            {!loading && members.length > 0 && (
              <div className="relative min-h-screen bg-gradient-to-br from-[#355e00] to-[#3467c6] py-20">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="absolute top-0 left-0 w-96 h-96 bg-white/5 rounded-full blur-3xl"></div>
                  <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/5 rounded-full blur-3xl"></div>
                </div>

                <div className="relative container mx-auto px-4">
                  <h2 className="text-6xl text-center text-white mb-20 tracking-wide tinos-regular uppercase">
                    {t("pages.home.section3.team")}
                  </h2>

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                    {members.map((member, index) => (
                      <motion.div
                        key={member._id}
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                        className="group relative"
                      >
                        <div className="relative overflow-hidden rounded-2xl bg-white/10 backdrop-blur-sm p-6 hover:shadow-2xl transition-all duration-500">
                          <div className="relative mb-6">
                            <div className="aspect-square overflow-hidden rounded-full border-4 border-white/20">
                              <img
                                src={`${member.img}`}
                                alt={member.name}
                                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                                loading="lazy"
                              />
                            </div>

                            <div className="absolute inset-0 rounded-full border-2 border-white/20 animate-spin-slow"></div>
                          </div>

                          <div className="text-center">
                            <h3 className="text-xl font-bold text-white mb-2">
                              {member.name}
                            </h3>
                            <p className="text-gray-300 mb-4">
                              {member.title ||
                                t(
                                  `pages.home.section3.members.${member.name
                                    .toLowerCase()
                                    .replace(/ /g, "")}.title`
                                )}
                            </p>

                            <div className="flex justify-center gap-4">
                              {member.social.map((item, idx) => (
                                <a
                                  key={`${member._id}-social-${idx}`}
                                  href={item.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-white/70 hover:text-white transition-colors"
                                >
                                  <item.icon className="text-xl" />
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {loading && (
              <div className="relative container mx-auto px-4 flex justify-center items-center min-h-[50vh]">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </section>
      </article>
    </main>
  );
};

export default Home;
