const API_CONFIG = {
  BASE_URL: import.meta.env.VITE_APP_API_URL || "http://localhost:8001",
  ADMIN_KEY: import.meta.env.VITE_ADMIN_API_KEY,
  TIMEOUT: 15000,
  HEADERS: {
    Accept: "application/json",
  },
};

const createRequestConfig = (customConfig = {}) => {
  const token = localStorage.getItem("token");

  const headers = new Headers({
    ...API_CONFIG.HEADERS,
    ...customConfig.headers,
  });

  if (!(customConfig.body instanceof FormData)) {
    headers.set("Content-Type", "application/json");
  }

  if ((customConfig.requiresJWT || !customConfig.isPublic) && token) {
    headers.set("authorization", token);
  }

  if (customConfig.requiresAdmin) {
    if (!API_CONFIG.ADMIN_KEY) {
      throw new Error("Admin API key is not configured");
    }
    headers.set("x-api-key", API_CONFIG.ADMIN_KEY);
  }

  return {
    ...customConfig,
    headers,
    timeout: API_CONFIG.TIMEOUT,
    credentials: "include",
    mode: "cors",
  };
};

export { API_CONFIG, createRequestConfig };
