import { BaseService } from "../api/BaseService";

class UserService extends BaseService {
  constructor() {
    super("/user");
  }

  async updateWalletAddress(walletAddress) {
    return this.put("/wallet", { walletAddress }, { requiresJWT: true });
  }

  async claimNFT(userId, nftId, claimDate) {
    return this.post(
      "/add-nft",
      { userId, nftId, claimDate },
      { requiresJWT: true }
    );
  }

  async updateProfile(profileData) {
    return this.put("/profile", profileData, { requiresJWT: true });
  }

  async changePassword(oldPassword, newPassword) {
    return this.put(
      "/change-password",
      { oldPassword, newPassword },
      { requiresJWT: true }
    );
  }

  async getProfile() {
    return this.get("/profile", { requiresJWT: true });
  }

  async getNFTs() {
    return this.get("/nfts", { requiresJWT: true });
  }
}

export const userService = new UserService();
