import {
  NetworkError,
  AuthenticationError,
  AuthorizationError,
  NotFoundError,
  ValidationError,
  ServerError,
} from "./errors";

export const responseInterceptor = async (response) => {
  if (!response.ok) {
    const errorData = await response.json().catch(() => null);
    const errorMessage =
      errorData?.message || `HTTP error! status: ${response.status}`;

    switch (response.status) {
      case 401:
        if (errorMessage.includes("API key")) {
          throw new AuthenticationError("Access denied. No API key provided.");
        }
        throw new AuthenticationError(errorMessage);
      case 403:
        if (errorMessage.includes("Admin key")) {
          throw new AuthorizationError("Invalid Admin key.");
        }
        throw new AuthorizationError(errorMessage);
      case 404:
        throw new NotFoundError(errorMessage);
      case 422:
        throw new ValidationError(errorMessage, errorData);
      case 500:
        throw new ServerError(errorMessage);
      default:
        throw new ServerError(errorMessage);
    }
  }
  return response.json();
};

export const errorInterceptor = (error) => {
  if (!error.response) {
    throw new NetworkError();
  }
  throw error;
};
