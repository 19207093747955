/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { createContext } from "react";
import { apiCache } from "../utils/cache";
import { adminService } from "../services/modules/adminService";

const WebContext = createContext();

const WebProvider = ({ children }) => {
  const fetchUsers = async () => {
    try {
      const cacheKey = "users_list";
      const cachedData = apiCache.get(cacheKey);

      if (cachedData && Array.isArray(cachedData)) {
        return { data: cachedData, error: null };
      }

      const response = await adminService.getAllUsers();

      if (!response) {
        return { data: [], error: "No data received" };
      }

      const users = response.users || [];

      if (!Array.isArray(users)) {
        return { data: [], error: "Invalid data format" };
      }

      apiCache.set(cacheKey, users, 300000);

      return { data: users, error: null };
    } catch (err) {
      if (err.message === "Unauthorized") {
        return { data: [], error: "Unauthorized" };
      }
      return { data: [], error: "Failed to fetch users" };
    }
  };

  const getUserByQuery = async (field, value) => {
    try {
      const data = await adminService.getUserByField(field, value);
      return { data, error: null };
    } catch (err) {
      return { data: null, error: "Failed to fetch user" };
    }
  };

  const createUser = async (userData) => {
    try {
      const data = await adminService.createUser(userData);

      apiCache.delete("users_list");
      apiCache.delete("user_leaderboard");

      return { success: true, data, error: null };
    } catch (err) {
      return { success: false, data: null, error: err.message };
    }
  };

  const deleteUser = async (id) => {
    try {
      await adminService.deleteUser(id);

      apiCache.delete("users_list");
      apiCache.delete("user_leaderboard");

      return { success: true, error: null };
    } catch (err) {
      return { success: false, error: err.message };
    }
  };

  const fetchNFTs = async () => {
    try {
      const cacheKey = "nfts_list";
      const cachedData = apiCache.get(cacheKey);

      if (cachedData && Array.isArray(cachedData)) {
        return { data: cachedData, error: null };
      }

      const response = await adminService.getAllNFTs();

      if (!response) {
        return { data: [], error: "No data received" };
      }

      const nfts = response.nfts || [];

      if (!Array.isArray(nfts)) {
        return { data: [], error: "Invalid data format" };
      }

      apiCache.set(cacheKey, nfts, 300000);

      return { data: nfts, error: null };
    } catch (err) {
      return { data: [], error: "Failed to fetch NFTs" };
    }
  };

  const createNFT = async (nftData) => {
    try {
      const data = await adminService.createNFT(nftData);

      apiCache.delete("nfts_list");
      apiCache.delete("nft_leaderboard");

      return { success: true, data, error: null };
    } catch (err) {
      return { success: false, data: null, error: err.message };
    }
  };

  const deleteNFT = async (id) => {
    try {
      await adminService.removeNFT(id);

      apiCache.delete("nfts_list");
      apiCache.delete("nft_leaderboard");

      return { success: true, error: null };
    } catch (err) {
      return { success: false, error: err.message };
    }
  };

  const updateUserRoles = async (userId, roles) => {
    try {
      const data = await adminService.updateUserRoles(userId, roles);
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const getUsersByRole = async (role) => {
    try {
      const data = await adminService.getUsersByRole(role);
      return data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  return (
    <WebContext.Provider
      value={{
        fetchUsers,
        createUser,
        deleteUser,
        fetchNFTs,
        createNFT,
        deleteNFT,
        updateUserRoles,
        getUsersByRole,
        getUserByQuery,
      }}
    >
      {children}
    </WebContext.Provider>
  );
};

export { WebContext, WebProvider };
