import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { useUser } from "../hooks/useUser";
import logoWhite from "../assets/Logo-White.webp";
import { FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";

export default function Footer() {
  const { t } = useTranslation();
  const date = new Date().getFullYear();
  const user = useUser();

  const socialLinks = [
    {
      name: "Instagram",
      url: "https://www.instagram.com/taublockchain/",
      icon: "fa-instagram",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/tdublockchain",
      icon: "fa-twitter",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/@taublockchain",
      icon: "fa-youtube",
    },
    {
      name: "Medium",
      url: "https://medium.com/ta%C3%BC-blockchain",
      icon: "fa-medium",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/ta%C3%BC-blockchain",
      icon: "fa-linkedin",
    },
    {
      name: "Discord",
      url: "https://discord.gg/TbVh5HF2m6",
      icon: "fa-discord",
    },
  ];

  return (
    <footer className="bg-gradient-to-br from-first to-second text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-1">
            <motion.img
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              src={logoWhite}
              alt="TGU Blockchain"
              className="h-16 w-auto mb-4"
            />
            <p className="text-sm text-white/80">
              {t("components.footer.description")}
            </p>
          </div>

          <div className="col-span-1">
            <h3 className="text-lg tinos-regular mb-4">
              {t("components.footer.quickLinks")}
            </h3>
            <ul className="space-y-2">
              <li>
                <NavLink
                  to="/about"
                  className="text-white/80 hover:text-white transition-colors duration-200"
                >
                  {t("components.footer.about")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/projects"
                  className="text-white/80 hover:text-white transition-colors duration-200"
                >
                  {t("components.footer.projects")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/events"
                  className="text-white/80 hover:text-white transition-colors duration-200"
                >
                  {t("components.footer.events")}
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-lg tinos-regular mb-4">
              {t("components.footer.contact")}
            </h3>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <FaMapMarkerAlt />
                <span>{t("components.footer.location")}</span>
              </div>
              <div className="flex items-center gap-2">
                <FaEnvelope />
                <span>{t("components.footer.email")}</span>
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <h3 className="text-lg tinos-regular mb-4">
              {t("components.footer.follow")}
            </h3>
            <div className="flex flex-wrap gap-4">
              {socialLinks.map((link) => (
                <motion.a
                  key={link.name}
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="text-white/80 hover:text-white transition-colors duration-200"
                >
                  <i className={`fab ${link.icon} text-2xl`}></i>
                </motion.a>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-white/10">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-white/60 text-sm">
              &copy; {date} {t("components.footer.footer")}
            </p>

            {(!user || Object.keys(user).length === 0) && (
              <div className="mt-4 md:mt-0">
                <NavLink
                  to="/signin"
                  className="px-6 py-2 bg-white/10 hover:bg-white/20 rounded-lg transition-colors duration-200"
                >
                  {t("components.footer.loginTitle")}
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}
