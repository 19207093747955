import { BaseService } from "../api/BaseService";

class AdminService extends BaseService {
  constructor() {
    super("/admin");
  }

  _getAdminConfig(customConfig = {}) {
    return {
      ...customConfig,
      requiresAdmin: true,
    };
  }

  async createMailList(mailListData) {
    return this.post("/mail-lists", mailListData, this._getAdminConfig());
  }

  async getAllMailLists() {
    return this.get("/mail-lists", this._getAdminConfig());
  }

  async updateMailList(id, mailListData) {
    return this.put(`/mail-lists/${id}`, mailListData, this._getAdminConfig());
  }

  async deleteMailList(id) {
    return this.delete(`/mail-lists/${id}`, this._getAdminConfig());
  }

  async manageSubscriber(mailListId, subscriberData) {
    return this.post(
      `/mail-lists/${mailListId}/subscribers`,
      subscriberData,
      this._getAdminConfig()
    );
  }

  async sendMailToList(mailListId, emailData) {
    return this.post(
      `/mail-lists/${mailListId}/send`,
      emailData,
      this._getAdminConfig()
    );
  }

  async createNFT(nftData) {
    return this.post("/create-nft", nftData, this._getAdminConfig());
  }

  async getAllNFTs() {
    return this.get("/nfts", this._getAdminConfig());
  }

  async deleteNFT(id) {
    return this.delete(`/nfts/${id}`, this._getAdminConfig());
  }

  async createUser(userData) {
    return this.post("/users", userData, this._getAdminConfig());
  }

  async createUserInfo(userInfoData) {
    return this.post("/create-users", userInfoData, this._getAdminConfig());
  }

  async getAllUsers() {
    return this.get("/users", this._getAdminConfig());
  }

  async getUser(id) {
    return this.get(`/users/${id}`, this._getAdminConfig());
  }

  async getUserByField(field, value) {
    return this.post(
      "/get-user-by-field",
      { field, value },
      this._getAdminConfig()
    );
  }

  async getUsersByRole(role) {
    return this.get(`/get-users-by-role/${role}`, this._getAdminConfig());
  }

  async deleteUser(id) {
    return this.delete(`/users/${id}`, this._getAdminConfig());
  }

  async updateUserRoles(userId, roles) {
    return this.put(
      `/update-user-roles`,
      { userId, roles },
      this._getAdminConfig()
    );
  }

  async sendLoginCredentials(userId) {
    return this.post(`/send-credentials/${userId}`, {}, this._getAdminConfig());
  }

  async sendUserInfo(userInfo) {
    return this.post("/send-user-info", userInfo, this._getAdminConfig());
  }

  async sendCredentials(userId) {
    return this.post(`/send-credentials/${userId}`, {}, this._getAdminConfig());
  }
}

export const adminService = new AdminService();
