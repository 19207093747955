class Cache {
  constructor() {
    this.cache = new Map();
  }

  set(key, value, ttl = 300000) {
    // Default TTL: 5 minutes
    const item = {
      value,
      expiry: Date.now() + ttl,
    };
    this.cache.set(key, item);
  }

  get(key) {
    const item = this.cache.get(key);
    if (!item) return null;

    if (Date.now() > item.expiry) {
      this.cache.delete(key);
      return null;
    }

    return item.value;
  }

  has(key) {
    return this.get(key) !== null;
  }

  delete(key) {
    this.cache.delete(key);
  }

  clear() {
    this.cache.clear();
  }
}

export const apiCache = new Cache();
