import { BaseService } from "../api/BaseService";

class AuthService extends BaseService {
  constructor() {
    super("/auth");
  }

  async signin(credentials) {
    const data = await this.post("/signin", credentials, { isPublic: true });
    if (data.token) {
      localStorage.setItem("token", data.token);
    }
    return data;
  }

  async signup(email) {
    return this.post("/signup", { email }, { isPublic: true });
  }

  async requestPasswordReset(email) {
    return this.post(
      "/request-password-reset",
      { mail: email },
      { isPublic: true }
    );
  }

  async resetPassword(token, password) {
    return this.post(
      `/reset-password/${token}`,
      { newPassword: password },
      { isPublic: true }
    );
  }

  signout() {
    localStorage.removeItem("token");
  }
}

export const authService = new AuthService();
