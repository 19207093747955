import i18n from "i18next";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { CiMenuBurger } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useUser } from "../hooks/useUser";
import logoWhite from "../assets/Logo-White.webp";
import SignOut from "./SignOut";

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { path: "/", label: "home" },
    { path: "/blog", label: "blog" },
    { path: "/events", label: "events" },
    { path: "/projects", label: "projects" },
    { path: "/buidl", label: "build", special: true },
  ];

  const languages = [
    { code: "en", label: "EN" },
    { code: "tr", label: "TR" },
    { code: "de", label: "DE" },
  ];

  return (
    <>
      <div className="h-20" />
      <motion.header
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        className="fixed w-full top-0 z-50 bg-gradient-to-r from-first to-second backdrop-blur-sm h-20"
      >
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex justify-between items-center h-full">
            <motion.div
              whileHover={{ scale: 1.02 }}
              onClick={() => navigate("/")}
              className="flex items-center space-x-4 cursor-pointer"
            >
              <img
                src={logoWhite}
                alt="TAU Blockchain Logo"
                className="h-16 w-auto"
                loading="eager"
              />
              <div className="border-l border-white/20 pl-4">
                <h1 className="font-acquire text-white text-xl leading-tight">
                  {t("components.header.headertitle")}
                  <br />
                  {t("components.header.headertitle2")}
                </h1>
              </div>
            </motion.div>

            <div className="hidden md:flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                {menuItems.map((item) => (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    className={({ isActive }) => `
                      px-3 py-2 rounded-lg font-acquire text-lg transition-all duration-200
                      ${
                        item.special
                          ? "bg-white text-first hover:bg-opacity-90"
                          : "text-white hover:bg-white/10"
                      }
                      ${isActive && !item.special ? "bg-white/10" : ""}
                    `}
                  >
                    {t(`components.header.navbar.${item.label}`)}
                  </NavLink>
                ))}
              </div>

              {user && Object.keys(user).length > 0 && (
                <div className="flex items-center space-x-2">
                  <NavLink
                    to="/profile"
                    className="px-4 py-2 bg-yellow-500 text-white rounded-lg font-acquire text-lg hover:bg-yellow-600 transition-colors duration-200"
                  >
                    {t("components.header.navbar.profile")}
                  </NavLink>
                  <SignOut />
                </div>
              )}

              <div className="flex items-center space-x-2">
                {languages.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => i18n.changeLanguage(lang.code)}
                    className={`px-2 py-1 rounded font-bold text-sm transition-colors duration-200
                      ${
                        i18n.language === lang.code
                          ? "text-yellow-400"
                          : "text-white hover:text-yellow-400"
                      }`}
                  >
                    {lang.label}
                  </button>
                ))}
              </div>
            </div>

            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-white p-2"
            >
              {isMenuOpen ? <IoClose size={24} /> : <CiMenuBurger size={24} />}
            </motion.button>
          </div>
        </nav>

        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden bg-gradient-to-b from-first/95 to-second/95 backdrop-blur-sm"
            >
              <div className="px-4 py-6 space-y-4">
                <div className="flex flex-col space-y-2">
                  {menuItems.map((item) => (
                    <NavLink
                      key={item.path}
                      to={item.path}
                      onClick={() => setIsMenuOpen(false)}
                      className={({ isActive }) => `
                        px-4 py-2 rounded-lg font-acquire text-lg transition-all duration-200 text-center
                        ${
                          item.special
                            ? "bg-white text-first hover:bg-opacity-90"
                            : "text-white hover:bg-white/10"
                        }
                        ${isActive && !item.special ? "bg-white/10" : ""}
                      `}
                    >
                      {t(`components.header.navbar.${item.label}`)}
                    </NavLink>
                  ))}
                </div>

                {user && Object.keys(user).length > 0 && (
                  <div className="flex flex-col space-y-2">
                    <NavLink
                      to="/profile"
                      onClick={() => setIsMenuOpen(false)}
                      className="px-4 py-2 bg-yellow-500 text-white rounded-lg font-acquire text-lg hover:bg-yellow-600 transition-colors duration-200 text-center"
                    >
                      {t("components.header.navbar.profile")}
                    </NavLink>
                    <SignOut className="w-full" />
                  </div>
                )}

                <div className="flex justify-center space-x-4">
                  {languages.map((lang) => (
                    <button
                      key={lang.code}
                      onClick={() => {
                        i18n.changeLanguage(lang.code);
                        setIsMenuOpen(false);
                      }}
                      className={`px-3 py-1 rounded font-bold transition-colors duration-200
                        ${
                          i18n.language === lang.code
                            ? "text-yellow-400"
                            : "text-white hover:text-yellow-400"
                        }`}
                    >
                      {lang.label}
                    </button>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.header>
    </>
  );
}

export default Header;
