import { motion } from "framer-motion";
import PropTypes from "prop-types";

const variants = {
  fullScreen: "min-h-screen flex justify-center items-center",
  container: "flex justify-center items-center h-40",
  inline: "flex items-center",
  button: "flex items-center justify-center",
};

const spinnerVariants = {
  default:
    "w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin",
  small:
    "w-8 h-8 border-2 border-white border-t-transparent rounded-full animate-spin",
  gradient: "w-16 h-16 border-4 border-t-transparent rounded-full animate-spin",
  button: "animate-spin -ml-1 mr-3 h-5 w-5",
};

const LoadingSpinner = ({
  variant = "default",
  size = "default",
  className = "",
  dark,
}) => {
  const containerClass = variants[variant] || variants.container;
  const spinnerClass = spinnerVariants[size] || spinnerVariants.default;
  const borderColor = dark ? "border-gray-900" : "border-white";

  const renderSpinner = () => {
    if (size === "button") {
      return (
        <svg
          className={`${spinnerClass} ${dark ? "text-gray-900" : "text-white"}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      );
    }

    return (
      <div
        className={`${spinnerClass} ${borderColor} ${
          variant === "gradient" ? "from-first to-second bg-gradient-to-br" : ""
        }`}
      />
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`${containerClass} ${className}`}
    >
      {renderSpinner()}
    </motion.div>
  );
};

LoadingSpinner.propTypes = {
  variant: PropTypes.oneOf(["fullScreen", "container", "inline", "button"]),
  size: PropTypes.oneOf(["default", "small", "button"]),
  className: PropTypes.string,
  text: PropTypes.string,
  dark: PropTypes.bool,
};

export default LoadingSpinner;
