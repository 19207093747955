import { Routes, Route, HashRouter } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { Suspense, lazy } from "react";

// Import translations
import enTranslation from "./locales/en.json";
import trTranslation from "./locales/tr.json";
import deTranslation from "./locales/de.json";

// Lazy load pages
const Blog = lazy(() => import("./pages/Blog"));
const BlogItem = lazy(() => import("./pages/BlogItem"));
const Buidl = lazy(() => import("./pages/Buidl"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ManageNFTs = lazy(() => import("./pages/ManageNFTs"));
const ManageUsers = lazy(() => import("./pages/ManageUsers"));
const ManageMembers = lazy(() => import("./pages/ManageMembers"));
const Events = lazy(() => import("./pages/Events"));
const SignIn = lazy(() => import("./pages/SignIn"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Projects = lazy(() => import("./pages/Projects"));
const Profile = lazy(() => import("./pages/Profile"));
const NFTShowcase = lazy(() => import("./pages/NFTShowcase"));
const NFTClaim = lazy(() => import("./pages/NFTClaim"));
const ManageAdmins = lazy(() => import("./pages/ManageAdmins"));
const ManageEvents = lazy(() => import("./pages/ManageEvents"));
const ManageProjects = lazy(() => import("./pages/ManageProjects"));
const ManageMailList = lazy(() => import("./pages/ManageMailList"));
const Unauthorized = lazy(() => import("./pages/Unauthorized"));
const RequestReset = lazy(() => import("./pages/RequestReset"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const About = lazy(() => import("./pages/About"));
const NFTCollection = lazy(() => import("./pages/NFTCollection"));

// Import non-lazy loaded components
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";
import PrivateRoute from "./routes/PrivateRoute";
import AuthedRoute from "./routes/AuthedRoute";
import LoadingSpinner from "./components/LoadingSpinner";
// Initialize i18n with eager loading
i18n.use(initReactI18next).init({
  lng: navigator.language.split("-")[0],
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: enTranslation,
    },
    tr: {
      translation: trTranslation,
    },
    de: {
      translation: deTranslation,
    },
  },
});

function App() {
  const routes = {
    public: [
      { path: "/", element: Home },
      { path: "/about", element: About },
      { path: "/blog", element: Blog },
      { path: "/blog/:id", element: BlogItem },
      { path: "/buidl", element: Buidl },
      { path: "/events", element: Events },
      { path: "/signin", element: SignIn },
      { path: "/signup", element: SignUp },
      { path: "/projects", element: Projects },
      { path: "/nft-claim/:id", element: NFTClaim },
      { path: "/unauthorized", element: Unauthorized },
      { path: "/reset-request", element: RequestReset },
      { path: "/reset-password/:token", element: ResetPassword },
    ],
    private: [
      { path: "/profile", element: Profile },
      { path: "/nft-showcase", element: NFTShowcase },
      { path: "/nft-collection", element: NFTCollection },
    ],
    admin: [
      {
        path: "/manage-nfts",
        element: ManageNFTs,
        roles: ["admin"],
      },
      {
        path: "/manage-users",
        element: ManageUsers,
        roles: ["admin"],
      },
      {
        path: "/manage-admins",
        element: ManageAdmins,
        roles: ["admin"],
      },
      {
        path: "/manage-members",
        element: ManageMembers,
        roles: ["admin"],
      },
      {
        path: "/manage-events",
        element: ManageEvents,
        roles: ["admin"],
      },
      {
        path: "/manage-projects",
        element: ManageProjects,
        roles: ["admin"],
      },
      {
        path: "/manage-maillist",
        element: ManageMailList,
        roles: ["admin"],
      },
    ],
  };

  return (
    <HashRouter>
      <Header />
      <ScrollToTop />
      <Suspense fallback={<LoadingSpinner variant="fullScreen" />}>
        <Routes>
          {routes.public.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.element />}
            />
          ))}

          {routes.private.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PrivateRoute>
                  <route.element />
                </PrivateRoute>
              }
            />
          ))}

          {routes.admin.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AuthedRoute requiredRoles={route.roles}>
                  <route.element />
                </AuthedRoute>
              }
            />
          ))}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <Footer />
    </HashRouter>
  );
}

export default App;
