/* eslint-disable react/prop-types */
import { createContext, useState, useEffect } from "react";
import { authService } from "../services/modules/authService";
import { userService } from "../services/modules/userService";
import { nftService } from "../services/modules/nftService";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const validateSession = async () => {
      try {
        const storedToken = localStorage.getItem("token");
        const storedUser = localStorage.getItem("user");

        if (!storedToken || !storedUser) {
          throw new Error("No stored session");
        }

        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setToken(storedToken);
      } catch (error) {
        console.error("Session validation failed:", error);
        setToken(null);
        setUser(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } finally {
        setIsLoading(false);
      }
    };

    validateSession();
  }, []);

  const signin = async (mail, password) => {
    setIsLoading(true);
    try {
      const response = await authService.signin({ mail, password });
      if (response.token && response.user) {
        const normalizedUser = {
          ...response.user,
          _id: response.user.id || response.user._id,
        };
        setToken(response.token);
        setUser(normalizedUser);
        localStorage.setItem("token", response.token);
        localStorage.setItem("user", JSON.stringify(normalizedUser));
        return normalizedUser;
      }
      throw new Error("Invalid response from server");
    } finally {
      setIsLoading(false);
    }
  };

  const signout = () => {
    authService.signout();
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  const fetchUserNFTs = async (nftInventory) => {
    try {
      if (
        !nftInventory ||
        !Array.isArray(nftInventory) ||
        nftInventory.length === 0
      ) {
        return [];
      }
      return await nftService.getNFTsByIds(nftInventory);
    } catch (error) {
      console.error("Error fetching user NFTs:", error);
      return [];
    }
  };

  const claimNFT = async (userId, nftId) => {
    if (!user?._id) throw new Error("User not authenticated");
    return await userService.claimNFT(user.id, nftId, new Date());
  };

  const updateWalletAddress = async (walletAddress) => {
    if (!user?._id) throw new Error("User not authenticated");
    const updatedUser = await userService.updateWalletAddress(walletAddress);
    setUser(updatedUser);
    localStorage.setItem("user", JSON.stringify(updatedUser));
    return updatedUser;
  };
  const checkRole = (role) => {
    if (!user || !user.roles) return false;
    return user.roles.includes(role);
  };
  return (
    <UserContext.Provider
      value={{
        user,
        token,
        isLoading,
        signin,
        signout,
        fetchUserNFTs,
        claimNFT,
        updateWalletAddress,
        checkRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
