import { BaseService } from "../api/BaseService";

class EventService extends BaseService {
  constructor() {
    super("/event");
  }

  async getAllEvents() {
    return this.get("/list", { isPublic: true });
  }

  async createEvent(eventData) {
    return this.post("/create", eventData, {
      requiresAdmin: true,
    });
  }

  async updateEvent(id, eventData) {
    return this.put(`/update/${id}`, eventData, {
      requiresAdmin: true,
    });
  }

  async deleteEvent(id) {
    return this.delete(`/delete/${id}`, {
      requiresAdmin: true,
    });
  }
}

export const eventService = new EventService();
