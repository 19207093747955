import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

const TOAST_TYPES = {
  success: {
    icon: "✓",
    bgColor: "bg-green-500",
    textColor: "text-white",
  },
  error: {
    icon: "✕",
    bgColor: "bg-red-500",
    textColor: "text-white",
  },
  warning: {
    icon: "⚠",
    bgColor: "bg-yellow-500",
    textColor: "text-white",
  },
  info: {
    icon: "ℹ",
    bgColor: "bg-blue-500",
    textColor: "text-white",
  },
};

const Toast = ({ message, type = "info", onClose, duration = 3000 }) => {
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const toastStyle = TOAST_TYPES[type] || TOAST_TYPES.info;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className={`fixed bottom-4 right-4 flex items-center gap-2 px-4 py-2 rounded-lg shadow-lg ${toastStyle.bgColor} ${toastStyle.textColor}`}
      >
        <span className="text-lg">{toastStyle.icon}</span>
        <p className="font-medium">{message}</p>
        <button
          onClick={onClose}
          className="ml-2 hover:opacity-80 transition-opacity"
        >
          ✕
        </button>
      </motion.div>
    </AnimatePresence>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "error", "warning", "info"]),
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number,
};

export default Toast;
