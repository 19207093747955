import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";

// eslint-disable-next-line react/prop-types
const AuthedRoute = ({ children }) => {
  const { user, checkRole, isLoading } = useContext(UserContext);
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="min-h-screen">
        <LoadingSpinner
          variant="fullScreen"
          text={t("components.authedRoute.loading")}
          dark
        />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/signin" />;
  }

  if (!checkRole("admin")) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default AuthedRoute;
