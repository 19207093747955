/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useToast } from "./ToastContext";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [globalError, setGlobalError] = useState(null);
  const { showToast } = useToast();

  const handleError = (error, type = "error", options = {}) => {
    const {
      setGlobal = false,
      message,
      showToast: shouldShowToast = true,
      toastOptions = {},
    } = options;

    let errorMessage = message;

    if (!errorMessage) {
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error?.message) {
        errorMessage = error.message;
      } else {
        errorMessage = "Bir hata oluştu";
      }
    }

    if (setGlobal) {
      setGlobalError({
        message: errorMessage,
        type,
        timestamp: Date.now(),
        originalError: error,
      });
    }

    if (shouldShowToast) {
      showToast(errorMessage, type, toastOptions.duration);
    }
  };

  const clearGlobalError = () => {
    setGlobalError(null);
  };

  return (
    <ErrorContext.Provider
      value={{ handleError, globalError, clearGlobalError }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};

export { ErrorContext };
