import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "react-fancy-circular-carousel/FancyCarousel.css";
import { ToastProvider } from "./context/ToastContext";
import { ErrorProvider } from "./context/ErrorContext";
import { WebProvider } from "./context/WebContext";
import { UserProvider } from "./context/UserContext";
import { HelmetProvider } from "react-helmet-async";

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <HelmetProvider>
      <ToastProvider>
        <ErrorProvider>
          <WebProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </WebProvider>
        </ErrorProvider>
      </ToastProvider>
    </HelmetProvider>
  </StrictMode>
);
