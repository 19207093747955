import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";

function SignOut() {
  const navigate = useNavigate();
  const { signout } = useContext(UserContext);

  const handleSignout = () => {
    signout();
    navigate("/signin");
  };

  return (
    <button
      type="button"
      className="transition-colors duration-300 ease-in-out rounded-md no-underline text-white bg-red-500 hover:bg-red-800 p-4 text-xl "
      onClick={handleSignout}
    >
      <FaSignOutAlt />
    </button>
  );
}

export default SignOut;
