/* eslint-disable react/prop-types */
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const SEOHelmet = ({
  title,
  description,
  keywords,
  image = "/logo192.png",
  url = "https://tau-blockchain.com",
}) => {
  const { i18n } = useTranslation();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      {/* Dil bilgisi */}
      <html lang={i18n.language} />
      <link rel="canonical" href={url} />

      {/* Alternatif dil linkleri */}
      <link rel="alternate" hrefLang="en" href={`${url}/en`} />
      <link rel="alternate" hrefLang="tr" href={`${url}/tr`} />
      <link rel="alternate" hrefLang="de" href={`${url}/de`} />
    </Helmet>
  );
};

export default SEOHelmet;
