import { API_CONFIG, createRequestConfig } from "./config";
import { responseInterceptor, errorInterceptor } from "./interceptors";

export class BaseService {
  constructor(baseURL = "") {
    this.baseURL = `${API_CONFIG.BASE_URL}${baseURL}`;
  }

  async request(endpoint, config = {}) {
    const url = `${this.baseURL}${endpoint}`;
    const token = localStorage.getItem("token");

    if (config.requiresAdmin) {
      if (!API_CONFIG.ADMIN_KEY) {
        console.error("Admin API key is missing");
        throw new Error("Access denied. No API key provided.");
      }
    }

    if (config.requiresJWT && !token) {
      console.error("JWT token is required for this operation");
      window.location.href = "/#/signin";
      return null;
    }

    if (!config.isPublic && !config.requiresJWT && !config.requiresAdmin) {
      if (!token) {
        window.location.href = "/#/signin";
        return null;
      }
    }

    const requestConfig = createRequestConfig(config);

    try {
      const response = await fetch(url, requestConfig);

      if (response.status === 401 && !config.requiresAdmin) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.href = "/#/signin";
        return null;
      }

      if (response.status === 401 && config.requiresAdmin) {
        throw new Error("Access denied. No API key provided.");
      }

      if (response.status === 403 && config.requiresAdmin) {
        throw new Error("Invalid Admin key.");
      }

      if (response.status === 403 && config.requiresJWT) {
        window.location.href = "/#/unauthorized";
        return null;
      }

      return await responseInterceptor(response);
    } catch (error) {
      return errorInterceptor(error);
    }
  }

  async get(endpoint, config = {}) {
    const response = await this.request(endpoint, { ...config, method: "GET" });
    if (response === null) {
      throw new Error(
        config.requiresAdmin
          ? "Unauthorized - Admin access required"
          : config.requiresJWT
          ? "Unauthorized - Authentication required"
          : "Unauthorized"
      );
    }
    return response;
  }

  async post(endpoint, data, config = {}) {
    const response = await this.request(endpoint, {
      ...config,
      method: "POST",
      body: data instanceof FormData ? data : JSON.stringify(data),
    });
    if (response === null) {
      throw new Error(
        config.requiresAdmin
          ? "Unauthorized - Admin access required"
          : config.requiresJWT
          ? "Unauthorized - Authentication required"
          : "Unauthorized"
      );
    }
    return response;
  }

  async put(endpoint, data, config = {}) {
    const response = await this.request(endpoint, {
      ...config,
      method: "PUT",
      body: data instanceof FormData ? data : JSON.stringify(data),
    });
    if (response === null) {
      throw new Error(
        config.requiresAdmin
          ? "Unauthorized - Admin access required"
          : config.requiresJWT
          ? "Unauthorized - Authentication required"
          : "Unauthorized"
      );
    }
    return response;
  }

  async patch(endpoint, data, config = {}) {
    const response = await this.request(endpoint, {
      ...config,
      method: "PATCH",
      body: JSON.stringify(data),
    });
    if (response === null) {
      throw new Error(
        config.requiresAdmin
          ? "Unauthorized - Admin access required"
          : config.requiresJWT
          ? "Unauthorized - Authentication required"
          : "Unauthorized"
      );
    }
    return response;
  }

  async delete(endpoint, config = {}) {
    const response = await this.request(endpoint, {
      ...config,
      method: "DELETE",
    });
    if (response === null) {
      throw new Error(
        config.requiresAdmin
          ? "Unauthorized - Admin access required"
          : config.requiresJWT
          ? "Unauthorized - Authentication required"
          : "Unauthorized"
      );
    }
    return response;
  }
}
