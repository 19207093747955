import { BaseService } from "../api/BaseService";

class NFTService extends BaseService {
  constructor() {
    super("/nft");
  }

  async getAllNFTs() {
    return this.post("/get-all-nfts", { isPublic: true });
  }

  async getNFTById(id) {
    return this.get(`/get-nft/${id}`, { isPublic: true });
  }

  async getNFTsByIds(nftIds) {
    try {
      const response = await this.post(
        "/get-nfts",
        { ids: nftIds },
        { requiresJWT: true }
      );
      return response;
    } catch (error) {
      console.error("NFT fetch error:", error);
      if (error.name === "NetworkError") {
        throw new Error(
          "Network error - please check if the backend server is running at " +
            this.baseURL
        );
      }
      throw error;
    }
  }

  async getNFTLeaderboard() {
    return this.post("/leaderboard/nfts", { isPublic: true });
  }

  async getUserLeaderboard() {
    return this.post("/leaderboard/users", { isPublic: true });
  }
}

export const nftService = new NFTService();
